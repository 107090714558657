/*
* 업무구분: 신탁>영업>변경/해지/이체>실시간입금>실시간계좌등록/해지
* 화 면 명: MSPTS210M
* 화면설명: 실시간입금[TFIO49000.xfdl:8282]
* 작 성 일: 2023.03.24
* 작 성 자: 박은희
*/
<template>
  <ur-page-container class="fts" :show-title="false" title="실시간입금관리" :topButton="true">
    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <mo-tab-box default-idx="1" class="fts-tab">
          <mo-tab-label idx="1"><router-link to="#">실시간계좌등록/해지</router-link></mo-tab-label>
          <!--<mo-tab-label idx="2"><router-link :to="{ name: 'MSPTS211M'}">실시간입금처리</router-link></mo-tab-label>-->
          <mo-tab-label idx="2" @click="fn_goPage()">실시간입금처리</mo-tab-label>
        </mo-tab-box>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left gap40">
            <div class="wrap-input row">
              <label> 계좌번호 </label>
              <mo-decimal-field 
                class="w130" 
                numeric
                mask="#######-###"
                v-model="div_tacno.tacno" 
                maxlength="7"
                @keyup="fn_SearchValid($event)"              
              />                   
              <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P"> </mo-button>
              <!--<mo-button class="btn-pop-download" @click="fn_OpenMSPTS206P"> </mo-button>-->         
              <mo-text-field class="w130" v-model="div_tacno.cnm" disabled/>
              
            </div>
            <div class="wrap-input row">
              <label> 비밀번호 </label> 
                <!-- 비밀번호 -->
                <m-trans-key-input
                  v-if="isMobile"
                  v-model="edt_pwd"                  
                  type="numberMax4"
                  maxlength="4"               
                  placeholder="비밀번호"
                  start='0'
                  end='-1'
                  dialog="Y"
                  :isClear="lv_isClear"
                  @masked-txt="fn_SetMaskedTxt">
                </m-trans-key-input>

                <mo-text-field
                  v-else
                  v-model="edt_pwd"
                  maxlength="4"                               
                  placeholder="비밀번호"
                  type="password"
                  @keyup="fn_PasswordValid($event)"
                  />
                <!-- 비밀번호 -->

            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button @click="fn_init"> 초기화 </mo-button>
              <mo-button primary @click="fn_searchList"> 조회 </mo-button>     
              <!-- <mo-button @click="fn_checkProcess()" primary> 전자문서 조회 </mo-button> -->
            </div>
          </div>
        </div>
        <div class="wrap-button result" >
          <mo-button v-if="isRstBtnDisabled" @click="fn_OpenMSPTS202P">신규계좌 등록</mo-button>          
        </div>
      </ur-box-container>
    
      <ur-box-container direction="column" alignV="start">                 
        <div class="wrap-table-title mt-0">
          <h2 class="table-title"> 신탁 실시간출금계좌 조회/등록/해지 </h2>
        </div>
        <div class="right-bg-white">
          <div class="wrap-table table-sticky sticky-t06 h-scroll-500">
            <table class="table row-type wsN">
              <thead>
                <tr>
                  <th> 등록상태 </th>
                  <th> 등록/해지처리 </th>
                  <th> 예금주명 </th>
                  <th> 은행명 </th>
                  <th> 은행계좌번호 </th>
                  <th> 실명번호 </th>
                  <th> 고객계좌관계 </th>
                  <th> 등록채널 </th>
                  <th> 처리일자 </th>
                  <th> 처리자 </th>
                  <th> 유선구분 </th>
                </tr>
              </thead>
              <tbody>
                <!--  RT 등록/해지 리스트 -->  
                <!--
                  // 첫번째 컬럼 타이틀 정보 procCls -> 01 (등록) -> 02 (해지)  else 미등록
                  // 두번째 컬럼 타이틀 정보 procCls -> 01 (해지) else 등록
                -->
                <tr v-for="(row, idx) in ds_tab1List" :key="idx">
                  <td> 
                    <mo-badge class="badge-type type03" :class="fn_reClass(row.procCls)" 
                    :text="fn_reTitle('1', row.procCls)" />
                  </td>
                  <td> 
                    <mo-button @click="fn_rtRstSelected(idx)"> 
                      <!-- 값을 체크하여 변환 -->
                    {{fn_reTitle('2', row.procCls)}}
                    </mo-button> 
                  </td>
                  <td> {{row.bnkAccDepoNm}} </td>
                  <td> {{row.bnkNm}} </td>
                  <td> {{stringBnkAcnoFormat(row.bnkAcno)}}</td>
                  <td> {{stringrDeporRrnoFormat(row.deporRrno)}}</td>
                  <td> {{row.custAccnRltnCdNm}} </td>
                  <td> {{row.accnTrtPathCdNm}} </td>
                  <td> {{dateYyMmDdFormat(row.procDate)}} </td> 
                  <td> {{row.procEnnm}} </td>
                  <td> {{row.telTypNm}} </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>

		  </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <!--<mo-button primary size="large" @click="fn_goPage()"> 확인 </mo-button>-->
          <mo-button primary size="large" @click="fn_checkProcessChk()"> 확인 </mo-button>          
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->

  <ts-alert-popup 
    ref="alertPopup"
    :popupObj="pAlertPopupObj"
  ></ts-alert-popup>
 
  <msp-ts-202p
    ref="popup202"
    :popup202Obj="pPopup202Obj"
    @ts-alert-popup="fn_AlertPopup"
    @ts-popup202-callback="fn_Popup202Back"
  ></msp-ts-202p>

  <msp-ts-107p
    ref="popup107"
    :popup107Obj="pPopup107Obj"
    @ts-alert-popup="fn_AlertPopup"
    @ts-popup107-callback="fn_Popup107Back"
  ></msp-ts-107p>

<!--
   <msp-ts-206p
    ref="popup206"
    :popup206Obj="pPopup206Obj"
    @ts-alert-popup="fn_AlertPopup"
    @ts-popup206-callback="fn_Popup206Back"
  ></msp-ts-206p>
-->

  </ur-page-container>
</template>
<script>


  const defaultSearchInfo = {
    tacno:'',     // 종합계조번호
    bnkbSeq:'',   // 일련번호
    accSeq:'',    // AccSeq
    brigAccYn:'',
    rcno:'',
    rcnoKindTc:'',
    csId:'',
    cnm:'',
  }

  
  const defaultTab1Search = {
    tacno:'',     // 종합계조번호
    pwd:'',   // 비밀번호
  }

 const defaultTab1List = {
    bnkAccDepoNm:'',
    bnkAcno:'',
    bnkNm:'',
    rcno:'',
    custAccnRltnCdNm:'',
    accnTrtPathCdNm:'',
    bkcd:'',
    regCls:'',
    regClsNm:'',
    deporRrno:'',
    acnoId:'',
    procDate:'',
    procEnnm:'',
    telTyp:'',
    procCls:'',
    rltiAccSeq:'',
    telTypNm:'',
    procObjAcno:'',
 }

  const defaultReport = {
    rltiAccSeq:'',
    tacno:'',
    bnkNm:'',
    bnkAcno:'',
    telTyp:'',
    pwdYn:'',
    procCls:'',
    procDate:'',
    bnkAccDepoNm:'',
    brnm:'',
    procEnnm:'',
    csId:'',
  } 

  const defaultTab1List01 = {
    rltiAccRegBusnTcNm:'',
    bnkAccDepoNm:'',
    bnkAcno:'',
    bkcd:'',
    csId:'',
    bknm:'',
    deporRrno:'',
    procDate:'',
    procEnnm:'',
    telTyp:'',
    rltiAccSeq:'',
    chkYn:'',
    accId:'',
    payno:'',
  }

  const defaultInsert01 = {
    bnkAccDepoNm:'',
    bnkAcno:'',
    bnkNm:'',
    bkcd:'',
    regCls:'',
    deporRrno:'',
    tacno:'',
    pwd:'',
    acnoId:'',
    rltiAccRegMedType:'',
    regTeleTypeYn:'',
    tmtlTeleTypeYn:'',
    rltiAccRegBusnTc:'',
    rltiAccSeq:'',
  }    
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  //import MSPTS206P from '@/ui/ts/MSPTS206P' //계좌번호 조회
  import MSPTS107P from '@/ui/ts/MSPTS107P' //고객번호 조회  
  import MSPTS202P from '@/ui/ts/MSPTS202P' //RT출금계좌 신규등록_P
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import TSHeader from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
  import TSInfoUtil from '~/src/ui/ts/comm/TSInfoUtil'    // Report (공통)
  import _cloneDeep from "lodash/cloneDeep"

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS210M",
    screenId: "MSPTS210M",
    components: {
        'ts-header': TSHeader,       
        'ts-alert-popup': TSAlertPopup,      
        'msp-ts-202p': MSPTS202P,
        //'msp-ts-206p': MSPTS206P,
        'msp-ts-107p': MSPTS107P,        


    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      let lv_vm = this
      window.fdpbridge.exec('RecordStatusPlugin', {}, (result) => {
        try {
          if (result.data === 'Playing') {
            lv_vm.fn_trace('info', '녹취 진행중...')
          } else if (result.data === 'Stop'){
            lv_vm.fn_trace('error', '녹취 중단 !!!', result.data)
            lv_vm.fn_AlertMsg('error', '녹취가 중단되었습니다.\n중단하시겠습니까?', '아니오|예')
          } 
        } catch (error) {
          lv_vm.fn_trace('error', '녹취 진행 중 오류 발생')
        }
      }, () => {
        lv_vm.fn_trace('error', '녹취 진행 중 오류 발생')
      })
    }
    ,

    mounted() {
      // 초기 로딩시 ds_tab1List 널일경우 표기 어떻게 해야하는지 몰라 우선  null  처리
      this.ds_tab1List = {}

      if(this.$route.params.tab1Tacno != null){
          this.tabObj = this.$route.params
          console.log('210M mounted this.tabObj ===================> ',this.tabObj)
          if (this.$route.params.tab1Tacno.length >= 7 ) {
            this.div_tacno.tacno = this.$route.params.tab1Tacno.substr(0,7)
            this.tabObj.tab1Tacno = this.$route.params.tab1Tacno
            //this.edt_pwd = this.$route.params.tab1Pwd
            this.fn_SearchValid()
            //this.tabObj.tab1SearchBtnClickYn = this.$route.params.tab1SearchBtnClickYn
            if(this.$route.params.tab1SearchBtnClickYn === 'Y') this.fn_searchList()
          }  

      }

    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {

        // 로그인정보
        lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data,
        lv_isProcess: this.getStore('tsStore').getters.getState.isProcess,
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,

        // 공통 객체
        pHeaderObj: {
          title: '실시간입금관리',
          //step: '7',
        },


        tabObj: {
          tab1Tacno: '',
          tab1Pwd: '',
          tab1SearchBtnClickYn: '',
          tab2Tacno: '',
          tab2AccSeqs: '',
          tab2BnkAccDepoNm: '',
          tab2Deposit: '',
          tab2SearchBtnClickYn: '',
          tab2SelectedIdx: '',
          tab2IsConsentConfirmBtnDisabled:'',   // 확인버튼
          tab2IsConsentCancelBtnDisabled:'',    // 출금해제
          tab2IsDepoReqBtnDisabled: '', //의뢰 버튼 활성화 여부
          tab2IsRdoDisabled: true, //알림톡발송 edit 여부
          tab2IsPaymAmt : true,    // 이체요청금액 edit  여부
          tab2IsPwd  : true,    // 비밀번호 edit  여부        
          tab2Rdo_smsYn   : '',
          tab2Edt_paymAmt: '',
          tab2Edt_pwd: '',    
          tab2AccSeq: '',        
        },        

        eaiCommObj: TSCommUtil.gfn_eaiCommObj(),


        searchBtnClickYn: 'N',
        // 팝업 객체
        pAlertPopupObj: {},
        pPopup202Obj: {},
        popup202: {}, // MSPTS202P
        pPopup202Type: '',
        /*
        pPopup206Obj: {},
        popup206: {}, // MSPTS202P        
        pPopup206Type: '',
        */
        pPopup107Obj: {},
        popup107: {}, // MSPTS107P
        pPopup107Type: '',


        //mspts210mList: [],
        selectedItem:{},
        selectedIdx:'',

        div_tacno: Object.assign({}, defaultSearchInfo),
        ds_tab1Search: Object.assign({}, defaultTab1Search),
        ds_tab1List: Object.assign({}, defaultTab1List),
        ds_tab1SortList: Object.assign({}, defaultTab1List),

        ds_tab1List01: Object.assign({}, defaultTab1List01),
        ds_insert01: Object.assign({}, defaultInsert01),
        
        ds_report: Object.assign({}, defaultReport),

        //ds_tfio46000        : {},   // 보고서 처리 신청
        ds_searchReportList : [],   // 보고서 출력조회
        ds_resultReportList : [],   // 보고서 출력조회
        ds_searchReport     : {},   // 보고서 출력조회

        ds_tmp_report: [],       // 등록/해지 후, 전자서식 출력을 위한 tmp 정보항목
        ds_tfio49000_report: [], // 등록/해지 후, 전자서식 출력을 위한 정보항목

        tacno:'',  //통합계좌번호
        edt_pwd:'',  //비밀번호
        cnm:'',  //고객명

        isRstBtnDisabled: false, //계좌등록 활성화 여부
        //isPrtBtnDisabled: false, //출력 활성화 여부

        tmtlTeleTypeYn:'',
        rltiAccRegBusnTc:'',
        bnkAccDepoNm:'',
        bnkAcno:'',
        bnkNm:'',
        bkcd:'',
        regCls:'',
        deporRrno:'',                
        regTeleTypeYn:'',  
        rltiAccRegMedType:'', 

        rltiAccSeq:'',  // 출력용 필요 정보 설정 실시간계좌일련번호 로 (AS-IS 에서는 리스트에서 선택한 정보가 온다. )

        lv_masked_val: '',          // 마스크 변수 체크
        lv_isClear: false,          // 보안키패드 초기화
        lv_isProcInUnit: false,     // 등록/해지 버튼 눌렀을 때

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

      // modal
      modal1() {return this.$refs.modal1},
      modal2() {return this.$refs.modal2},
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      // modal
      openModal(type) {
        switch (type) {
          case 1:
            this.modal1.open();
            break;

          case 2: 
            this.modal2.open();
            break;
        }
      },
      closeModal(type) {
        switch (type) {
          case 1:
            this.modal1.close();
            break;
          case 2:
            this.modal2.close();
            break; 
        }
      },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      switch (type) {
        case 0:
          if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
            this.pAlertPopupObj.content      = pPopupObj.content
            this.pAlertPopupObj.contentTitle = ''
            this.pAlertPopupObj.confirm      = false
            this.pAlertPopupObj.cancel       = true
            this.pAlertPopupObj.type         = 0

            if ( !TSCommUtil.gfn_isNull(pPopupObj.confirm) ) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm
            } else {
              this.pAlertPopupObj.confirm = ''
            }

            if ( !TSCommUtil.gfn_isNull(pPopupObj.confirmFunc) ) {
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
            } else {
              this.pAlertPopupObj.confirmFunc = ''
            }

            if ( !TSCommUtil.gfn_isNull(pPopupObj.closeFunc) ) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
            } else {
              this.pAlertPopupObj.closeFunc = ''
            }

            if ( !TSCommUtil.gfn_isNull(pPopupObj.confirmData) ) {
              this.pAlertPopupObj.confirmData = pPopupObj.confirmData
            } else {
              this.pAlertPopupObj.confirmData = ''
            }

            if ( !TSCommUtil.gfn_isNull(pPopupObj.single) ) {
              this.pAlertPopupObj.single = pPopupObj.single
            } else {
              this.pAlertPopupObj.single = ''
            }

          }
          break
        case 7:
        case 9:

          if ( !TSCommUtil.gfn_isNull(pPopupObj) ) {
            this.pAlertPopupObj.content      = pPopupObj.content          
            this.pAlertPopupObj.contentTitle = ''
            this.pAlertPopupObj.confirm      = false
            this.pAlertPopupObj.cancel       = true
            this.pAlertPopupObj.type         = 0

            if ( !pPopupObj.cancel ) {
              this.pAlertPopupObj.cancel = pPopupObj.cancel
            }
            if ( pPopupObj.confirm ) {
              this.pAlertPopupObj.confirm     = pPopupObj.confirm
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
              this.pAlertPopupObj.confirmData = pPopupObj.confirmData
            }
            if ( pPopupObj.closeFunc ) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
              this.pAlertPopupObj.closeData = pPopupObj.closeData
            }
            if ( !TSCommUtil.gfn_isNull(type) && type != 0 ) {
              this.pAlertPopupObj.type = type
            }
            if ( pPopupObj.contentTitle ) {
              this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle
            }
          }
          break
      }

      this.$refs.alertPopup.fn_Open()
    },


  /*********************************************************
    * Function명: fn_OpenMSPTS202P
    * 설명: RT출금계좌 신규등록 팝업호출
    *********************************************************/
    fn_OpenMSPTS202P (type) {
      let lv_vm = this
      this.pPopup202Type = type

      let properties = {
        pPage : 'MSPTS210M',
        pTacno : this.div_tacno.tacno,
        //pGdC : lv_vm.gdC,              // 신탁상품
        //pTrstTypeC : lv_vm.trstTypC,  // 신탁유형
        //pGdTypDtlC : lv_vm.gdTypDtlC,  // 상품유형
        pUrl : '2000',                 // 
        pProcTc : '2000',              //
      }

      this.popup202 = this.$refs.popup202.fn_Open(properties)      

    },
    /*********************************************************
    * Function명: fn_Popup202Back
    * 설명: 신규계좌 등록 팝업호출 콜백
    *********************************************************/
    fn_Popup202Back(rtnData){
      console.log('신규계좌 팝업 호출 콜백 rtnData =====================> ', rtnData)

      // 신규 계좌 등록 신청한 셋팅
      this.ds_tmp_report.push(rtnData)

      // 처리결과를 'Y' 인경우
      this.fn_searchList()
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 0: 종합통장정보
     ******************************************************************************/
    fn_Popup_CallBack(type, pData) {
      switch (type) {
        case 1:

          this.fn_dataClrear()

          this.tacno            = pData.tacno;    //계좌번호
          this.edt_pwd              = pData.edt_pwd;  // 비밀번호
          this.tmtlTeleTypeYn   = pData.tmtlTeleTypeYn;  // 유무선여부
          

          // 리스트 값 초기화 필요
          // 검색조건 초기화 필요
          break
      
      }
    },

    /******************************************************************************
     * Function명 : fn_dataClrear
     * 설명       : data init
     * 타입(type) : 0: 고객정보
     ******************************************************************************/
    fn_dataClrear() {
      this.tacno = "",
      this.edt_pwd= ""
      this.cnm= ""



      this.isRstBtnDisabled = false
      //this.isPrtBtnDisabled = false
    },

    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
      this.fn_PasswordValid()
    },

    /******************************************************************************
     * Function명 : fn_init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_init(){

      this.tabObj.tab1SearchBtnClickYn = 'N'
      this.isRstBtnDisabled = false

      this.div_tacno = Object.assign({}, defaultSearchInfo),
      this.ds_tab1Search = Object.assign({}, defaultTab1Search),
      this.ds_tab1List = Object.assign({}, defaultTab1List),
      this.ds_tab1List01 = Object.assign({}, defaultTab1List01),
      this.ds_insert01 = Object.assign({}, defaultInsert01) ,       
      this.ds_report = Object.assign({}, defaultReport),
      this.ds_tab1List = [],

      this.depositInputProc(2); //입금처리부분 활성화

      this.edt_pwd = ""; // 비밀번호(웹일반) 정합성 체크를 위한 초기화
      this.lv_masked_val = ""; // 비밀번호(보안키패드) 정합성 체크를 위한 초기화
      this.lv_isClear = !this.lv_isClear ? true : false;  // 비밀번호(보안키패드) 정합성 체크를 위한 초기화

    },

    /******************************************************************************
     * Function명 : depositInputProc
     * 설명       : data init
     * 타입(type) : 0: 초기화
     ******************************************************************************/
    depositInputProc(deposit_mode){
      if(deposit_mode == 1){
        //입금정보
        //알림톡발송여부 활성화
        //이체용청금액 활성화
        //비밀번호 활성화
        //의뢰버튼 활성화
        //확인(동의)버튼 비활성화
        //동의취소 활성화
        //그리드 선택기능 비활성화
      }else{
        //console.log(deposit_mode)


        this.edt_pwd = ''

        //알림톡발송여부 비활성화
        //이체용청금액 비활성화
        //비밀번호 비활성화
        //의뢰버튼 비활성화  
        //확인(동의)버튼 활성화
        //동의취소 비활성화
        //그리드 선택기능 활성화              
      }
    },

    /******************************************************************************
       * Function명 : fn_PasswordValid
       * 설명       : 필수값 검증 후 조회
       ******************************************************************************/
      fn_PasswordValid(event) {

        let t_accn_no = this.div_tacno.tacno.replace(/[^0-9]/g, '').substr(0,10)

        if( TSCommUtil.gfn_length(this.edt_pwd) == 4 || TSCommUtil.gfn_length(this.lv_masked_val) == 4 ) {    
         
  
         // 기존 검색 결과 초기화 함수 필요함
          this.eaiCommObj.lv_vm = this
          //this.eaiCommObj.trnstId = 'txTSSTS49S8' // selectTFIO49000AList
          this.eaiCommObj.auth = 'S'
          this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600247_S'
          this.eaiCommObj.params = {
              acctNo: t_accn_no,
              pwd   : this.edt_pwd,
              pwdCls : 'N',
          }
          console.log('this.eaiCommObj.params ==============> ',this.eaiCommObj.params)
  
          TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_passwordCheckResult)
        }
      },


     /******************************************************************************
       * Function명 : fn_passwordCheckResult
       * 설명       : 비밀번호 체크 후처리
       ******************************************************************************/
      fn_passwordCheckResult(pResultData) {

        console.log('pResultData ;' , pResultData)

        if ( !TSCommUtil.gfn_isNull ( pResultData.data.errorMsg) ){
          this.fn_AlertPopup(0,{ content : pResultData.data.errorMsg} )
          this.edt_pwd = ''
          if(this.isMobile) { //Mobile 
            this.lv_masked_val = ''
            this.lv_isClear = !this.lv_isClear ? true : false  // 비밀번호(보안키패드) 정합성 체크를 위한 초기화
          }
          return
        } 
 

      },

      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회
       ******************************************************************************/
      fn_SearchValid(event) {
        if( TSCommUtil.gfn_length(this.div_tacno.tacno) == 7 ) {

          if( !TSCommUtil.gfn_isNum(this.div_tacno.tacno) ) {
              TSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
            return 

          } else {
              this.fn_transaction()
          }
        }

      },


    /******************************************************************************
     * Function명 : fn_transaction
     * 설명       : 
     ******************************************************************************/
     fn_transaction(){
      this.ds_tab1Search.tacno = this.div_tacno.tacno

      // 기존 검색 결과 초기화 함수 필요함
      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49S8' // selectTFIO49000AList
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600215_S'
      this.eaiCommObj.params = {
          tacno:this.ds_tab1Search.tacno,
      }

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_transactionResult) 

     } ,


    /******************************************************************************
     * Function명 : fn_transactionResult
     * 설명       : 
     ******************************************************************************/
    fn_transactionResult(pResultData) {

      console.log('fn_transactionResult pResultData =========================> ',pResultData)
        
        let t_data = pResultData.data


        if ( !TSCommUtil.gfn_isNull ( t_data.errorMsg ) ){
            this.fn_AlertPopup(0,{ content :  t_data.errorMsg} )
            return
        }

        //  this.ds_tab1List = pResultData.data.tfio49000
        this.div_tacno.cnm = t_data.cnm
        this.div_tacno.csId = t_data.csId
        this.div_tacno.rcnoKindTc = t_data.rcnoKindTc
        this.div_tacno.accSeq = t_data.accSeq
        this.div_tacno.bnkbSeq = t_data.bnkbSeq
        this.div_tacno.tacno = t_data.tacno

        console.log('this.div_tacno.csId ===================> ',this.div_tacno.csId)
    },     

    /******************************************************************************
     * Function명 : fn_search
     * 설명       : RT출금계좌 조회
     ******************************************************************************/
    fn_searchList () {
      // 입력/선택 필수값 검증    
      if (this.div_tacno.tacno.trim().length < 1) {
          this.fn_AlertPopup(0,{ content : '통장번호를 입력하세요.'} )
          return 
      } 
  
      if(this.isMobile) {
      
        if (TSCommUtil.gfn_isNull (this.lv_masked_val) || TSCommUtil.gfn_length (this.lv_masked_val) != 4) {
          this.fn_AlertPopup (0, {
            confirm: false,
            single: true,
            content: "비밀번호를 입력하십시오.",
          });
          return;
        } 

        this.fn_PasswordValid() // Mobile 비밀번호 체크로직 추가

      } else {

        if ( TSCommUtil.gfn_isNull ( this.edt_pwd ) ){
            this.fn_AlertPopup(0,{ content : '비밀번호를 입력하세요.'} )
            return
        } 

        if( TSCommUtil.gfn_length(this.edt_pwd) != 4 ) {
            this.fn_AlertPopup(0,{ content : '비밀번호는 4자리의 숫자로 입력하여 주십시오.'} )
            return 
        }
      }


      this.tabObj.tab1SearchBtnClickYn = 'Y'

      this.ds_tab1Search.tacno = this.div_tacno.tacno
      this.ds_tab1Search.pwd = this.div_tacno.edt_pwd

      // 기존 검색 결과 초기화 함수 필요함
      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49S1'  // selectTFIO49000AList
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600178_S'
      this.eaiCommObj.params = {
          tacno:this.ds_tab1Search.tacno,
          pwd:this.ds_tab1Search.pwd,
      }

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_searchListResult)      

    },

    /******************************************************************************
    * Function명 : fn_searchListResult
    * 설명       : RT출금계좌 결과
    ******************************************************************************/
    fn_searchListResult(pResultData) {
      
      console.log('no sort ' , pResultData.data.tfio49000)

      this.isRstBtnDisabled = true                                  
      
      if(pResultData.data.tfio49000.length > 0){
        this.ds_tab1List =  pResultData.data.tfio49000.sort(function (a, b) {
            return Number(a.procCls) - Number(b.procCls)
        });  
      }else{
        this.ds_tab1List = []
      }

      if ( this.ds_tmp_report.length > 0 ) {
        // 이탈 프로세스 Report 셋팅
        this.lv_isProcInUnit = true

        // 이탈 프로세스 처리 시 커버 발행 파라미터 오류 수정
        if (this.lv_isProcInUnit) { // 이탈 프로세스 관련 커버 파라미터 셋팅
          this.$bizUtil.tsUtils.saveCvrPblParam (this, {
            tacno: this.div_tacno.tacno,
            bnkbSeq: this.div_tacno.bnkbSeq,
            accSeq: this.div_tacno.accSeq,
            csId: this.div_tacno.csId,
          });
        }

        this.fn_confirmPrintTrue()
      }

      console.log('fn_searchListResult this.ds_tab1List ============================> ', this.ds_tab1List)

    },

      /******************************************************************************
       * Function명 : fn_RowSelected
       * 설명       : 조회내역 선택
       ******************************************************************************/
      fn_rtRstSelected(selectedIdx){
        // procCls != "01"    rcno != deporRrno 이면
        //this.gfn_getMessage( "alert", "실시간계좌는 본인명의만 등록할 수 있습니다.");
        //procCls == "01"  confirm 수탁 RT출금계좌를 해지하시겠습니까?
        // else            confirm 수탁 RT출금계좌로 등록하시겠습니까?

        let procCls = ""
        let rcno = ""
        let deporRrno = ""
        this.selectedIdx = selectedIdx
        
        this.selectedItem = this.ds_tab1List[selectedIdx]
        procCls = this.ds_tab1List[selectedIdx].procCls
        rcno = this.ds_tab1List[selectedIdx].rcno
        deporRrno = this.ds_tab1List[selectedIdx].deporRrno        

        if ( procCls !== '01' && rcno !== deporRrno){
            this.fn_AlertPopup(0,{ content : '실시간계좌는 본인명의만 등록할 수 있습니다.'} )
            return
        }

        let t_confirm_data = {
          tacno: this.div_tacno.tacno,
          bnkbSeq: this.div_tacno.bnkbSeq,
          accSeq: this.div_tacno.accSeq,
          csId: this.div_tacno.csId,
        }

        if (procCls == "01") 
        {
          let lv_text = "계좌등록을 해지하시겠습니까?";
          this.fn_AlertPopup(0,{ content : lv_text, confirm: true, confirmFunc: this.callbackConfirm, closeFunc: this.callbackClose, confirmData: t_confirm_data} )
        }else{
          let lv_text = "계좌를 등록하시겠습니까?";
          this.fn_AlertPopup(0,{ content : lv_text, confirm: true, confirmFunc: this.callbackConfirm, closeFunc: this.callbackClose, confirmData: t_confirm_data } )

        }

      },


      /******************************************************************************
       * Function명 : callbackConfirm
       * 설명       : 
       ******************************************************************************/
      callbackConfirm(){
        let procCls = this.selectedItem.procCls

        this.ds_tmp_report = [] // 초기화

        if(procCls == '01'){
          this.fn_rtAcctTerminate(this.selectedItem)

          // 계좌 등록·해지 신청한 데이터만 셋팅
          this.selectedItem.procCls = '02'
          this.ds_tmp_report.push(this.selectedItem)

        }else{
          this.fn_rtAcctRegist(this.selectedItem)

          this.selectedItem.procCls = '01'
          // 계좌 등록·해지 신청한 데이터만 셋팅
          this.ds_tmp_report.push(this.selectedItem)
        }

        console.log('callbackConfirm this.ds_tmp_report =================> ',this.ds_tmp_report)

        this.fn_searchList()

        // 이탈 프로세스 Report 셋팅
        //this.lv_isProcInUnit = true
        //this.fn_confirmPrintTrue()

      },


      /******************************************************************************
       * Function명 : callbackClose
       * 설명       : 
       ******************************************************************************/
      callbackClose(){
        //console.log('close action')
      },

      /******************************************************************************
       * Function명 : fn_newAccRgt
       * 설명       : 
       ******************************************************************************/
      fn_newAccRgt(){
          // 신규계좌등록 팝업 호출한다. 
      },

      /******************************************************************************
       * Function명 : fn_reClass
       * 설명       : 
       ******************************************************************************/
      fn_reClass(procCls){
        let classNm = "";
        if(procCls === '01')      classNm = 'possible'
        else                      classNm = 'impossible'
        return classNm
      },


      /******************************************************************************
       * Function명 : fn_reTitle
       * 설명       : 
       ******************************************************************************/
      fn_reTitle(gubun, procCls){
        let title = "";
        if(gubun === '1'){
          if(procCls === '01')      title = '등록'
          else if(procCls === '02') title = '해지'
          else                      title = '미등록'
        }else{
          if(procCls === '01')      title = '해지'
          else                      title = '등록'
        }
        return title
      },


      /******************************************************************************
       * Function명 : fn_rtAcctRegist
       * 설명       : 실시간계좌 등록
       ******************************************************************************/
      fn_rtAcctRegist(selectedItem){
          //insertTFIO49000A
        // 해당 값의 등록 / 해지 구분을 처리하여 등록 처리한다.
          // AS-IS는 팝업에서 선택한 값을 리턴 받아 처리하는데...(유무선 구분값 Y OR N):유선내방구분
          // TO-BE는  
 

          this.ds_insert01.rltiAccRegBusnTc=this.selectedItem.rltiAccRegBusnTc
          this.ds_insert01.bnkAccDepoNm=this.selectedItem.bnkAccDepoNm
          this.ds_insert01.bnkAcno=this.selectedItem.bnkAcno
          this.ds_insert01.bnkNm=this.selectedItem.bnkNm
          this.ds_insert01.bkcd=this.selectedItem.bkcd
          this.ds_insert01.rltiAccRegBusnTc=this.selectedItem.regCls
          this.ds_insert01.deporRrno=this.selectedItem.deporRrno
          this.ds_insert01.tacno=this.div_tacno.tacno
          this.ds_insert01.pwd=this.edt_pwd
          this.ds_insert01.acnoId=this.selectedItem.acnoId
          this.ds_insert01.regTeleTypeYn=this.selectedItem.regTeleTypeYn
          this.ds_insert01.rltiAccRegMedType='I'


          this.eaiCommObj.lv_vm = this
          //this.eaiCommObj.trnstId = 'txTSSTS49I1' // insertTFIO49000A
          this.eaiCommObj.auth = 'I'
          this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600174_S'
          this.eaiCommObj.params = {
            
            
            rltiAccRegBusnTc  :this.ds_insert01.rltiAccRegBusnTc,
            bnkAccDepoNm      :this.ds_insert01.bnkAccDepoNm,
            bnkAcno           :this.ds_insert01.bnkAcno,
            bnkNm             :this.ds_insert01.bnkNm,
            bkcd              :this.ds_insert01.bkcd,
            regCls            :this.ds_insert01.regCls,
            deporRrno         :this.ds_insert01.deporRrno,
            tacno             :this.ds_insert01.tacno,
            pwd               :this.ds_insert01.pwd,
            acnoId            :this.ds_insert01.acnoId,
            regTeleTypeYn     :this.ds_insert01.regTeleTypeYn,
            rltiAccRegMedType :this.ds_insert01.rltiAccRegMedType,
              
          }
   
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_rtAcctRegistCallBack)          
      },

      fn_rtAcctRegistCallBack(res) {

        if ( !TSCommUtil.gfn_isNull ( res.data.errorMsg ) ){
            this.fn_AlertPopup(0,{ content :  res.data.errorMsg} )
            return
        }
        //this.fn_searchList()
          // AS-IS 콜백 소스 아무 처리 없음 
          // 개인적인 생각
          // mspts210mList 의 index 값에 해당 하는 정보를 재구성 한다.
          // 등록버튼 클릭시 등록상태는 등록 , 등록/해지는 해지
          // 해지버튼 클릭시 등록상태는 미등록 , 등록/해지는 등록 
          // 으로 변경해야하는가 확인 필요
          //this.fn_printReg(this.selectedItem)

      },

      /******************************************************************************
       * Function명 : fn_rtAcctRegist
       * 설명       : 실시간계좌 해지
       ******************************************************************************/
      fn_rtAcctTerminate(selectedItem){
            //updateTFIO49000
        this.ds_insert01.tacno  = this.div_tacno.tacno
        this.ds_insert01.pwd  = this.edt_pwd
        this.ds_insert01.rltiAccSeq  = selectedItem.rltiAccSeq
        this.ds_insert01.tmtlTeleTypeYn  = ''
        this.ds_insert01.rltiAccRegMedType  = 'I'

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS49U1' // updateTFIO49000
        this.eaiCommObj.auth = 'U'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600183_S'
        this.eaiCommObj.params = {
            tacno             : this.ds_insert01.tacno,
            pwd               : this.ds_insert01.pwd,
            rltiAccSeq        : this.ds_insert01.rltiAccSeq,
            tmtlTeleTypeYn    : this.ds_insert01.tmtlTeleTypeYn,
            rltiAccRegMedType : this.ds_insert01.rltiAccRegMedType,
        }
 
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_rtAcctTerminateCallBack) 
        
      },

      /******************************************************************************
       * Function명 : fn_rtAcctTerminateCallBack
       * 설명       : 실시간계좌 해지 후처리
       ******************************************************************************/
      fn_rtAcctTerminateCallBack(res){

        console.log('errorMsg :' ,res)  

        /*
        if ( !TSCommUtil.gfn_isNull ( res.data.errorMsg ) ){
            this.fn_AlertPopup(0,{ content :  res.data.errorMsg} )
            return
        }*/

      },  
      
      /************************************************************************************************
       * Function명 : dateYyMmDdFormat
       * 설명       : 날짜 형식 년월 표시 ( YYYYMMDD => YYYY-MM-DD )
       * @param {String} value : 날짜 형식 변경
       ************************************************************************************************/
      dateYyMmDdFormat (value) {
        if (!value) return ''
        value = TSCommUtil.gfn_dateReplace(1, value)
        return value
      },


      /************************************************************************************************
       * Function명 : stringBnkAcnoFormat
       * 설명       : 352*****56710
       * @param {String} value : 통장번호 형식 변경
       ************************************************************************************************/
      stringBnkAcnoFormat (value) {
        if (!value) return ''
        value = TSCommUtil.gfn_bnkAcno_mask(value)
        return value
      },      

      /************************************************************************************************
       * Function명 : stringrDeporRrnoFormat
       * 설명       : 880322-1******
       * @param {String} value : 주민번호 형식 변경
       ************************************************************************************************/
      stringrDeporRrnoFormat (value) {
        if (!value) return ''
        value = TSCommUtil.gfn_jumin_mask(value)
        return value
      },    


      /******************************************************************************
       * Function명 : fn_goPage
       * 설명       : 입금처리 화면 이동
       ******************************************************************************/        
      fn_goPage(){

        if ( TSCommUtil.gfn_isNull(this.tabObj.tab1Tacno) ) {
          this.tabObj.tab1Tacno = this.div_tacno.tacno
        } 
        this.$route.params.tab2Tacno = this.tabObj.tab1Tacno
        //this.tabObj.tab1Pwd = this.edt_pwd
        //this.tabObj.tab2SearchBtnClickYn = 'Y'
        console.log('MSPTS210M->11M goPage', this.tabObj)

        this.$router.push({ name: 'MSPTS211M', params:this.tabObj })
      },      


     /*********************************************************
      * Function명: fn_OpenMSPTS107P
      * 설명: 고객조회 팝업호출
      *********************************************************/
      fn_OpenMSPTS107P (type) {
        let lv_vm = this
        this.pPopup107Type = type
        
        let properties = {
          pPage : 'MSPTS210M',
          pUrl : '2000',                 // 
          pProcTc : '2000',              //
        }

        this.popup107 = this.$refs.popup107.fn_Open(properties)      
     
      },
      /*********************************************************
      * Function명: fn_OpenMSPTS107P
      * 설명: 계좌조회 팝업호출 콜백
      *********************************************************/
      fn_Popup107Back(rtnData){

        console.log('고객 리턴 ', rtnData)
       // this.ds_acno = rtnData
       // this.ds_acno.acno = rtnData.tacno
        this.div_tacno.tacno = rtnData.tacno
        this.div_tacno.cnm = rtnData.cnm        
      },       


      /*********************************************************
      * Function명: fn_OpenMSPTS206P
      * 설명: 계좌번호 조회 팝업호출
      *********************************************************/
      fn_OpenMSPTS206P (type) {

        let lv_vm = this
        this.pPopup206Type = type

        let properties = {
          pPage : 'MSPTS210M',             
          //pGdC : lv_vm.gdC,              // 신탁상품
          //pTrstTypeC : lv_vm.trstTypC,  // 신탁유형
          //pGdTypDtlC : lv_vm.gdTypDtlC,  // 상품유형
          pUrl : '2000',                 // 
          pProcTc : '2000',              //
        }

        this.popup206 = this.$refs.popup206.fn_Open(properties)
      },
      /*********************************************************
      * Function명: fn_Popup206Back
      * 설명: 계좌번호 조회 팝업호출 콜백
      *********************************************************/
      fn_Popup206Back(rtnData){
        this.div_tacno.tacno = rtnData.tacno+rtnData.bnkbSeq
        this.div_tacno.bnkAccDepoNm = rtnData.cnm

        this.fn_transaction()

        //this.div_tacno.accSeqs.push({value: rtnData.accSeq , text: rtnData.gdNm})
        //this.div_tacno.accSeq = rtnData.accSeq


      },

      /******************************************************************************
       * Function명 : fn_checkProcessChk
       * 설명       : 프로세스에 분기 전에 체크
       ******************************************************************************/
      fn_checkProcessChk() {

      console.log('this.ds_tab1List.length =================> ', this.ds_tab1List.length)
      if (this.ds_tab1List.length > 0 ) { //조회 데이터가 있을시에만 프로세스 처리
        this.fn_checkProcess()
      }

      },
      /******************************************************************************
       * Function명 : fn_checkProcess
       * 설명       : 프로세스에 따라 분기 처리
       ******************************************************************************/
      fn_checkProcess() {

        let lv_Vm = this;
        let tmpContentTitle = '실시간출금계좌 조회/등록/해지 완료'        
        let tmpContent = [] 

        let isProcess = lv_Vm.getStore('tsStore').getters.getState.isProcess
        let basInfo   = lv_Vm.getStore('tsStore').getters.getBasInfo.data
        let isLast    = lv_Vm.$bizUtil.tsUtils.getIsLastProc(lv_Vm)
        let t_type    = 0        

        if ( isProcess ) {          
          if ( !isLast ) {
            if ( basInfo.procTyp === 'custInfoReg' ) {
              tmpContent.push('실시간입금처리 화면으로 이동합니다.')
            }
            t_type = 7 // 프로세스 진행 중 마지막 화면이 아닐 경우
            if ( TSCommUtil.gfn_isNull(this.div_tacno.bnkbSeq) ) {
              this.div_tacno.bnkbSeq = this.tabObj.tab1Tacno.substr(7,3)
              this.div_tacno.accSeq  = this.tabObj.accSeq
            }

          } else {
            t_type = 9 // 프로세스 진행 중 마지막 화면일 경우
          }

          t_type = 7

        } else { // 프로세스 진행 중이 아닐 경우

          t_type = 9

        }        
        
        console.log('fn_checkProcess isProcess ==================> ',isProcess)
        console.log('fn_checkProcess isProcess t_type ==================> ',t_type)

        let t_popupObj = {
          confirm      : true,
          confirmFunc  : isProcess ? this.fn_goPage : this.fn_searchReport,
          content      : tmpContent,
          contentTitle : tmpContentTitle,
          confirmData  : {
            tacno: this.div_tacno.tacno,
            bnkbSeq: this.div_tacno.bnkbSeq,
            accSeq: this.div_tacno.accSeq,
            csId: this.div_tacno.csId,
          },
        }
        console.log('fn_checkProcess t_popupObj ================> ',t_popupObj)
        lv_Vm.fn_AlertPopup(t_type, t_popupObj)

      },


      /******************************************************************************
       * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
       * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
       ******************************************************************************/
      async fn_confirmPrintTrue() {   
        
        this.ds_searchReportList = []

        this.ds_searchParam = {}; // 초기화

        // 리포트 파라미터 내 파라미터
        this.ds_searchParam.csId = this.div_tacno.csId;

        // 전자서명 동의서
        this.ds_searchReport.reportMrdNm = "TS000001";
        this.ds_searchReport.reportUrl = "selectTFCP10000";
        this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
        this.ds_searchReportList.push (JSON.parse (JSON.stringify (this.ds_searchReport)));


        // 계좌 등록·해지 신청서 전자서식
        this.ds_tfio49000_report = _.cloneDeep (this.ds_tmp_report);

        console.log('this.ds_tfio49000_report ================> ', this.ds_tfio49000_report)

        if ( this.ds_tfio49000_report.length > 0 ) {

          this.ds_tfio49000_report.forEach ((elmnt, idx) => {

            if ( this.ds_tfio49000_report[idx].telTypNm == '일반') {

              this.ds_searchParam = {

                tacno         : this.div_tacno.tacno                                    ,
                rltiAccSeq    : this.ds_tfio49000_report[idx].rltiAccSeq                ,
                bnkNm         : this.ds_tfio49000_report[idx].bnkNm                     ,    
                bnkAcno       : this.ds_tfio49000_report[idx].bnkAcno                   ,   
                telTyp        : this.ds_tfio49000_report[idx].telTyp                    ,
                pwdYn         : this.ds_tfio49000_report[idx].pwdYn                     ,    
                procCls       : this.ds_tfio49000_report[idx].procCls                   ,
                procDate      : TSCommUtil.fn_strToday()                                ,
                bnkAccDepoNm  : this.ds_tfio49000_report[idx].bnkAccDepoNm              ,                       
                brnm          : this.ds_tfio49000_report[idx].brnm                      ,                   
                procEnnm      : this.getStore('userInfo').getters.getUserInfo.userNm    ,   
                csId          : this.div_tacno.csId                                     ,

              }

              this.ds_searchReport.reportMrdNm = "TS000046" //실시간계좌등록해지신청서
              this.ds_searchReport.reportUrl   = "reportTFIO49000" 
              this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
              this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

            }

          });
        }

        console.log('this.ds_tfio49000_report ================> ',this.ds_tfio49000_report) 
	
        //this.fn_searchReport()
        this.fn_initReport()
        this.fn_searchReport()
      },


      /***************************************************************************************
          리포트 데이터 조회 
      *****************************************************************************************/
      fn_initReport() {
        this.ds_resultReportList = []
        for ( let i = 0; i < this.ds_searchReportList.length; i++ ) {
          let report = {formId : this.ds_searchReportList[i].reportMrdNm}
          report.params = this.ds_searchReportList[i]
          this.ds_resultReportList.push(JSON.parse(JSON.stringify(report)))
        }
        console.log('fn_initReport this.ds_resultReportList ==========================>', this.ds_resultReportList)
      },


    /***************************************************************************************
        리포트 데이터 조회 
    *****************************************************************************************/
    async fn_searchReport() {      
      //console.log('fn_searchReport START ==========================>')
      if(this.lv_isProcInUnit) {
        console.log('fn_searchReport this.ds_resultReportList ==========================>', this.ds_resultReportList)
        TSInfoUtil.commReportInfo(this, this.ds_resultReportList, {} , false)
        this.lv_isProcInUnit = false
        return
      }

      let isProcess = this.getStore('tsStore').getters.getState.isProcess
      let isLast    = this.$bizUtil.tsUtils.getIsLastProc(this)

      if ( isProcess ) { 
        if ( !isLast ) {
          TSInfoUtil.commReportInfo(this, this.ds_resultReportList, this.fn_goPage , false)  
        } else {
          TSInfoUtil.commReportInfo(this, [], function() {}, true, '', true)
        }
      } else { // 프로세스 진행 중이 아닐 경우
        TSInfoUtil.commReportInfo(this, [], function() {}, true, '', true)
      }
      //console.log('fn_searchReport END ==========================>')
    },


  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    
  }
}
</script>

<style lang="scss">
</style>

<style scoped>
</style>
